export enum AccessibilityRole {
  BUTTON = 'button',
  CHECKBOX = 'checkbox',
  DIALOG = 'dialog',
  FOOTER = 'contentinfo',
  FORM = 'form',
  GROUP = 'group',
  HEADER = 'banner',
  HEADING = 'heading',
  IMAGE = 'image',
  LINK = 'link',
  LIST = 'list',
  LISTITEM = 'listitem',
  MAIN = 'main',
  NAVIGATION = 'navigation',
  RADIO = 'radio',
  RADIOGROUP = 'radiogroup',
  SWITCH = 'switch',
  STATUS = 'status',
  TAB = 'tab',
  TABLIST = 'tablist',
  TABPANEL = 'tabpanel',
  TOOLTIP = 'tooltip',
}
