import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

function FilterSvg({ size, color, accessibilityLabel, testID }: AccessibleIcon) {
  return (
    <AccessibleSvg
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 48 49"
      accessibilityLabel={accessibilityLabel}
      testID={testID}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 6.5C30.3431 6.5 29 7.84315 29 9.5C29 11.1569 30.3431 12.5 32 12.5C33.6569 12.5 35 11.1569 35 9.5C35 7.84315 33.6569 6.5 32 6.5ZM27.1 10.5C27.5633 12.7822 29.581 14.5 32 14.5C34.7614 14.5 37 12.2614 37 9.5C37 6.73858 34.7614 4.5 32 4.5C29.581 4.5 27.5633 6.21776 27.1 8.5H5C4.44772 8.5 4 8.94772 4 9.5C4 10.0523 4.44772 10.5 5 10.5H27.1ZM39 9.5C39 8.94772 39.4477 8.5 40 8.5H43C43.5523 8.5 44 8.94772 44 9.5C44 10.0523 43.5523 10.5 43 10.5H40C39.4477 10.5 39 10.0523 39 9.5ZM12 21.5C10.3431 21.5 9 22.8431 9 24.5C9 26.1569 10.3431 27.5 12 27.5C13.6569 27.5 15 26.1569 15 24.5C15 22.8431 13.6569 21.5 12 21.5ZM7 25.5C7.03342 25.5 7.06646 25.4984 7.09904 25.4952C7.56043 27.7798 9.57933 29.5 12 29.5C14.7614 29.5 17 27.2614 17 24.5C17 21.7386 14.7614 19.5 12 19.5C9.57933 19.5 7.56043 21.2202 7.09904 23.5048C7.06646 23.5016 7.03342 23.5 7 23.5H5C4.44772 23.5 4 23.9477 4 24.5C4 25.0523 4.44772 25.5 5 25.5H7ZM20 23.5C19.4477 23.5 19 23.9477 19 24.5C19 25.0523 19.4477 25.5 20 25.5H43C43.5523 25.5 44 25.0523 44 24.5C44 23.9477 43.5523 23.5 43 23.5H20ZM29 39.5C29 37.8431 30.3431 36.5 32 36.5C33.6569 36.5 35 37.8431 35 39.5C35 41.1569 33.6569 42.5 32 42.5C30.3431 42.5 29 41.1569 29 39.5ZM27.1 40.5C27.5633 42.7822 29.581 44.5 32 44.5C34.7614 44.5 37 42.2614 37 39.5C37 36.7386 34.7614 34.5 32 34.5C29.581 34.5 27.5633 36.2178 27.1 38.5H5C4.44772 38.5 4 38.9477 4 39.5C4 40.0523 4.44772 40.5 5 40.5H27.1ZM39 39.5C39 38.9477 39.4477 38.5 40 38.5H43C43.5523 38.5 44 38.9477 44 39.5C44 40.0523 43.5523 40.5 43 40.5H40C39.4477 40.5 39 40.0523 39 39.5Z"
        fill={color}
      />
    </AccessibleSvg>
  )
}

export const Filter = styled(FilterSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
