/* eslint-disable local-rules/no-hardcoded-id-in-svg */
import * as React from 'react'
import { G, Path, Defs, LinearGradient, Stop, Rect } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from '../../types'

function FirstLiveMusicBookingUnlockedDetailedSvg({
  size,
  accessibilityLabel,
  testID,
}: AccessibleIcon) {
  return (
    <AccessibleSvg
      width={size}
      height={size}
      viewBox="0 0 260 260"
      accessibilityLabel={accessibilityLabel}
      testID={testID}>
      <G fill="none" fillRule="evenodd">
        <Rect width="260" height="260" fill="white" />
        <Path
          d="M238.706 215.558C238.706 215.558 139.545 254.796 130.655 254.796C121.764 254.796 22.3743 215.956 22.3743 215.956C18.0326 215.962 17.8004 48.9266 22.1453 48.9203C22.1453 48.9203 124.573 5 130.658 5C136.743 5 238.48 48.5227 238.48 48.5227C240.474 48.5227 240.703 215.555 238.709 215.558H238.706Z"
          fill="url(#paint0_linear_8631_23024)"
        />
        <Path
          d="M130.658 254.8C139.548 254.8 238.709 215.561 238.709 215.561C240.703 215.561 240.474 48.5226 238.48 48.5258C238.48 48.5258 136.743 5.00317 130.658 5.00317V254.803V254.8Z"
          fill="url(#paint1_linear_8631_23024)"
        />
        <Path
          d="M234.571 54.7379C234.571 54.7379 136.482 12.4812 130.616 12.4812C124.751 12.4812 25.9941 55.1228 25.9941 55.1228C21.805 55.1291 22.0277 217.298 26.2168 217.292C26.2168 217.292 122.044 255 130.616 255C139.189 255 234.793 216.904 234.793 216.904C236.715 216.904 236.495 54.7315 234.571 54.7347V54.7379ZM226.075 210.081C226.075 210.081 138.483 244.984 130.629 244.984C122.776 244.984 34.9798 210.434 34.9798 210.434C31.1438 210.44 30.9403 61.8597 34.7763 61.8565C34.7763 61.8565 125.257 22.7901 130.629 22.7901C136.002 22.7901 225.871 61.5034 225.871 61.5034C227.634 61.5034 227.837 210.081 226.075 210.081Z"
          fill="#BCB6C3"
        />
        <Path
          d="M238.477 48.5227C238.477 48.5227 136.739 5 130.658 5C124.576 5 22.1453 48.9203 22.1453 48.9203C17.8035 48.9266 18.0326 215.962 22.3743 215.956C22.3743 215.956 121.764 254.796 130.655 254.796C139.545 254.796 238.706 215.558 238.706 215.558C240.7 215.558 240.471 48.5195 238.477 48.5227ZM229.663 208.529C229.663 208.529 138.813 244.481 130.667 244.481C122.521 244.481 31.4618 208.895 31.4618 208.895C27.4827 208.901 27.2727 55.8639 31.2519 55.8575C31.2519 55.8575 125.095 15.6175 130.667 15.6175C136.24 15.6175 229.453 55.4918 229.453 55.4918C231.279 55.4918 231.492 208.526 229.663 208.529Z"
          fill="url(#paint2_linear_8631_23024)"
        />
        <Path
          d="M47.7219 70.311V198.605C47.7219 198.678 47.7665 198.745 47.8301 198.773L106.143 224.29C106.729 224.547 107.244 223.809 106.799 223.348L67.7259 182.726C67.7004 182.701 67.6845 182.666 67.6782 182.631L48.0877 70.2792C48.0495 70.0597 47.7251 70.0852 47.7251 70.311H47.7219Z"
          fill="#E3DEED"
        />
        <Path
          d="M209.713 70.311V198.605C209.713 198.678 209.668 198.745 209.605 198.773L151.291 224.29C150.706 224.547 150.191 223.809 150.636 223.348L189.709 182.726C189.734 182.701 189.75 182.666 189.757 182.631L209.347 70.2792C209.385 70.0597 209.71 70.0852 209.71 70.311H209.713Z"
          fill="#EFEEF0"
        />
        <Path
          d="M155.91 115.815C155.91 115.02 155.47 114.326 154.8 113.919C154.4 113.67 153.93 113.512 153.41 113.512H136.24V113.54H120.68C119.32 113.54 118.22 114.557 118.22 115.815V119.67H118.16V133.326H118.22V156.7C118.22 157.855 119.15 158.798 120.36 158.946V227.236L126.97 233.985H131.32V240.263C131.32 241.77 132.64 243 134.28 243H139.73C141.36 243 142.69 241.77 142.69 240.263V233.985H147.03L153.64 227.236V158.956C153.92 158.937 154.19 158.882 154.43 158.78C154.71 158.669 154.97 158.512 155.18 158.308C155.63 157.892 155.9 157.328 155.9 156.7V133.326H155.96V119.67H155.9V115.815H155.91Z"
          fill="#7F8AA5"
        />
        <Path
          d="M176.24 65.342C163.11 65.1478 149.92 65.342 136.79 65.342C149.92 65.342 163.11 65.1385 176.24 65.342C176.64 64.8427 176.33 64.3526 176.21 63.8441C172.11 46.7856 155.55 34.6367 136.79 35.0342C135.28 35.062 133.67 34.914 132.19 35.0805C115.26 36.9204 100.04 49.4854 97.72 65.342C96.49 65.3604 95.24 65.3235 94 65.342V78.4248C95.23 78.4432 96.48 78.4063 97.72 78.4248C100.49 96.2691 117.52 108.945 136.79 109.075C156.59 109.204 173.05 96.3893 176.61 78.434C177.72 78.4155 178.85 78.4525 179.96 78.434V65.342C178.72 65.3235 177.47 65.3604 176.24 65.342ZM108.24 59.6835C108.48 59.0641 108.67 58.5093 108.81 58.2319C108.67 58.5186 108.47 59.0641 108.24 59.6835ZM127.11 42.1073C127.08 43.3185 121.26 45.371 119.94 46.1199C121.26 45.371 127.09 43.3185 127.11 42.1073ZM107.61 53.5443C106.61 55.1623 104.5 58.9161 104.41 61.1259C104.49 58.9254 106.6 55.1623 107.61 53.5443ZM106.85 62.3925C106.56 62.7254 106.25 62.9473 105.9 62.975C106.24 62.9473 106.56 62.7254 106.85 62.3925ZM119.56 97.6468C115.33 95.3908 111.63 91.8774 109.19 87.9572C108.99 87.6336 108.65 86.9309 108.27 86.145C108.64 86.9309 108.98 87.6336 109.19 87.9572C111.63 91.8681 115.33 95.3815 119.56 97.6468ZM103.74 83.5562C103.93 82.6131 104.66 82.0306 105.47 82.1046C104.67 82.0306 103.94 82.6131 103.74 83.5562ZM106.64 82.7518C106.64 82.7518 106.64 82.7333 106.63 82.7333C106.63 82.7333 106.63 82.7518 106.64 82.7518ZM106.63 82.7241C106.63 82.7241 106.58 82.6593 106.57 82.6408C106.51 82.5854 106.45 82.5391 106.38 82.4929C106.44 82.5391 106.5 82.5854 106.57 82.6408C106.59 82.6593 106.61 82.6871 106.63 82.7241ZM103.85 85.0355C104.59 87.5596 107.91 91.9791 110.6 94.873C107.9 91.9791 104.59 87.5596 103.85 85.0355ZM127.2 102.963C128.02 102.501 128.21 101.585 127.33 100.79C128.22 101.585 128.02 102.501 127.2 102.963ZM107.48 78.508C117.25 78.5357 127.04 78.4248 136.79 78.4248C127.03 78.4248 117.25 78.5357 107.48 78.508Z"
          fill="#7F8AA5"
        />
        <Path
          d="M146.641 156.081H113.363V229.911H146.641V156.081Z"
          fill="url(#paint3_linear_8631_23024)"
        />
        <Path d="M146.547 229.712V159.629H137.731V229.712H146.547Z" fill="#AE7FDB" />
        <Path
          d="M137.951 229.712V159.629H118.337V229.712H137.951Z"
          fill="url(#paint4_linear_8631_23024)"
        />
        <Path d="M146.641 155.885H113.363V160.292H146.641V155.885Z" fill="#8153AD" />
        <Path d="M140.03 237.208H119.974L113.363 229.911H146.641L140.03 237.208Z" fill="#8153AD" />
        <Path d="M148.845 114.868H111.159V128.312H148.845V114.868Z" fill="#265CEC" />
        <Path
          d="M146.445 106.934H113.687C112.326 106.934 111.223 108.037 111.223 109.398V153.617C111.223 154.977 112.326 156.081 113.687 156.081H146.445C147.806 156.081 148.909 154.977 148.909 153.617V109.398C148.909 108.037 147.806 106.934 146.445 106.934Z"
          fill="url(#paint5_linear_8631_23024)"
        />
        <Path
          d="M148.856 109.347V153.731C148.856 155.012 147.816 156.052 146.536 156.052H129.242V106.906H146.412C147.761 106.906 148.856 108.001 148.856 109.35V109.347Z"
          fill="url(#paint6_linear_8631_23024)"
        />
        <Path
          d="M148.971 113.57H111.159V128.336H148.971V113.57Z"
          fill="url(#paint7_linear_8631_23024)"
        />
        <Path
          d="M135.686 237.205V243.996C135.686 245.626 134.361 246.951 132.73 246.951H127.278C125.647 246.951 124.323 245.626 124.323 243.996V237.205H135.688H135.686Z"
          fill="url(#paint8_linear_8631_23024)"
        />
        <Path
          d="M130.568 226.849C133.429 226.849 135.748 224.531 135.748 221.67C135.748 218.81 133.429 216.491 130.568 216.491H129.561C126.701 216.491 124.382 218.81 124.382 221.67C124.382 224.531 126.701 226.849 129.561 226.849H130.568Z"
          fill="url(#paint9_linear_8631_23024)"
        />
        <Path
          d="M169.61 68.9635C166.053 88.3885 149.597 102.246 129.793 102.11V68.9635C143.042 68.9679 156.365 69.2214 169.61 68.9635Z"
          fill="url(#paint10_linear_8631_23024)"
        />
        <Path
          d="M169.238 54.8102C156.111 54.5942 142.923 54.8058 129.793 54.8102V22.0363C148.552 21.6022 165.11 34.7483 169.209 53.1948C169.33 53.7435 169.634 54.2681 169.238 54.8102Z"
          fill="url(#paint11_linear_8631_23024)"
        />
        <Path
          d="M129.793 68.9634V102.11C110.52 101.978 93.4883 88.2584 90.7202 68.9634C103.723 69.1816 116.785 68.959 129.793 68.9634ZM99.5644 73.5211C98.1517 72.0863 96.2916 73.4616 96.7544 75.7646C97.4442 79.2005 103.097 86.6716 105.804 89.0628C108.267 91.2381 116.032 96.0712 119.192 95.8089C120.761 95.6789 121.508 94.2904 120.323 93.1488C119.604 92.4568 114.286 90.7422 112.561 89.746C108.329 87.3019 104.629 83.5002 102.191 79.2688C101.603 78.2462 99.8377 73.801 99.5644 73.5211Z"
          fill="url(#paint12_linear_8631_23024)"
        />
        <Path
          d="M129.793 22.0363V54.8102C116.785 54.8146 103.723 54.592 90.7202 54.8102C93.0453 37.6662 108.265 24.0683 125.191 22.0826C126.677 21.9085 128.285 22.0694 129.795 22.0341L129.793 22.0363ZM117.414 28.0771C111.798 29.2254 103.75 36.8067 100.84 41.6618C99.7782 43.436 96.2806 49.9286 97.7859 51.6542C99.9236 54.1027 101.239 48.344 101.819 47.112C104.369 41.6949 107.758 37.2012 112.935 34.0276C114.26 33.2166 120.082 30.9929 120.109 29.6838C120.14 28.0661 118.727 27.8083 117.414 28.0771Z"
          fill="url(#paint13_linear_8631_23024)"
        />
        <Path
          d="M169.238 54.8102C170.474 54.8301 171.724 54.7948 172.96 54.8102V68.9679C171.847 68.9834 170.721 68.9437 169.61 68.9679C156.367 69.2258 143.045 68.9723 129.793 68.9679V54.8146C142.923 54.8102 156.111 54.5986 169.238 54.8146V54.8102Z"
          fill="#8153AD"
        />
        <Path
          d="M90.7201 54.8101C103.723 54.5919 116.785 54.8145 129.793 54.8101V68.9634C116.785 68.959 103.723 69.1816 90.7201 68.9634C89.4838 68.9436 88.2342 68.9789 87 68.9634V54.8101C88.2364 54.7947 89.486 54.8299 90.7201 54.8101Z"
          fill="#B784EA"
        />
        <Path
          d="M99.5642 73.5212C99.8375 73.7988 101.603 78.2463 102.191 79.2689C104.629 83.5025 108.329 87.302 112.56 89.7461C114.286 90.7423 119.602 92.4547 120.323 93.1489C121.506 94.2905 120.761 95.679 119.192 95.809C116.029 96.0712 108.267 91.2381 105.803 89.0629C103.097 86.6717 97.4463 79.2006 96.7542 75.7647C96.2914 73.4617 98.1537 72.0864 99.5642 73.5212Z"
          fill="#F7F0CF"
        />
        <Path
          d="M117.414 28.0771C118.727 27.8082 120.14 28.0661 120.109 29.6837C120.082 30.9928 114.26 33.2165 112.935 34.0276C107.761 37.2012 104.369 41.6949 101.819 47.112C101.239 48.344 99.9236 54.1027 97.7858 51.6542C96.2806 49.9308 99.7781 43.4359 100.84 41.6618C103.75 36.8067 111.798 29.2231 117.414 28.0771Z"
          fill="#F7F0CF"
        />
        <Path
          d="M132.281 132.997C136.094 134.956 135.292 147.92 132.933 149.784C132.206 150.359 128.323 150.507 127.556 150.273C125.899 149.771 125.008 145.625 124.786 144.081C132.164 144.704 133.736 138.445 130.979 132.671C131.045 132.673 132.065 132.935 132.283 132.997H132.281Z"
          fill="#363544"
        />
        <Path
          d="M130.976 132.671C133.731 138.445 132.16 144.702 124.783 144.081C124.263 140.484 124.113 136.583 126.738 133.65C128.594 132.757 128.739 132.589 130.976 132.671Z"
          fill="url(#paint14_linear_8631_23024)"
        />
        <Path
          d="M126.74 133.647C124.113 136.581 124.265 140.482 124.785 144.078C125.008 145.623 125.901 149.769 127.556 150.271C128.325 150.505 132.206 150.357 132.933 149.782C135.291 147.917 136.094 134.952 132.281 132.995C137.579 134.478 136.768 150.441 131.792 151.576C131.47 151.649 127.448 151.642 127.227 151.576C122.906 150.287 121.837 136.008 126.738 133.647H126.74Z"
          fill="#F8F8FD"
        />
        <Path
          d="M119.397 170.982C119.397 169.765 118.854 168.778 118.185 168.778C117.515 168.778 116.973 169.765 116.973 170.982V210.872C116.973 212.09 117.515 213.076 118.185 213.076C118.854 213.076 119.397 212.09 119.397 210.872V170.982Z"
          fill="#F8F8FD"
        />
        <Path
          d="M67.8329 81.1327C67.2405 80.726 67.2661 81.9301 67.138 82.1767C64.0157 88.1492 62.7155 91.5662 56 94.5893C62.6386 97.3882 64.1438 101.92 67.138 107.7C67.2437 107.908 67.2501 108.923 67.8329 108.513C69.6327 102.029 73.245 97.6732 79.2015 94.5925C73.2322 91.7103 69.9658 87.3454 67.8329 81.1359V81.1327Z"
          fill="#7F8AA5"
        />
        <Path
          d="M68.4927 105.65C68.6432 103.755 69.13 100.728 68.7233 98.9222C69.0883 98.7684 68.8674 98.4546 69.1876 97.9935C71.0738 95.2842 71.8616 93.5517 75.221 92.3092C72.1242 91.8544 70.997 89.1772 68.957 87.3198C68.9506 87.1661 68.9634 87.0092 68.957 86.8554C68.8545 84.6298 69.3477 82.1703 68.4927 80.1272C68.6208 79.8806 68.5951 78.6765 69.1876 79.0832C71.3236 85.2927 74.5869 89.6576 80.5562 92.5397C74.5965 95.6205 70.9874 99.979 69.1876 106.461C68.6016 106.874 68.5984 105.855 68.4927 105.647V105.65Z"
          fill="#E5C3FF"
        />
        <Path
          d="M68.9571 86.8554C67.4968 89.1963 65.5657 90.9961 63.1575 92.3091C65.191 93.4748 68.2077 96.6067 68.7265 98.9221C69.13 100.728 68.6464 103.755 68.4959 105.65C65.5049 99.87 63.9965 95.3418 57.3579 92.5396C64.0734 89.5134 65.3736 86.0964 68.4959 80.1271C69.351 82.1702 68.8578 84.6297 68.9603 86.8554H68.9571Z"
          fill="#C58CF0"
        />
        <Path
          d="M68.9568 87.3197C69.0913 90.8712 69.5269 94.3874 69.1874 97.9934C68.8672 98.4545 69.0881 98.7683 68.7231 98.9221C68.2043 96.6067 65.1876 93.4747 63.1541 92.3091C65.5623 90.9993 67.4965 89.1963 68.9536 86.8553C68.96 87.0091 68.9472 87.166 68.9536 87.3197H68.9568Z"
          fill="#D7A2FF"
        />
        <Path
          d="M69.1878 97.9935C69.5241 94.3908 69.0886 90.8713 68.9573 87.3198C71.0004 89.174 72.1245 91.8512 75.2212 92.3092C71.8619 93.5517 71.0741 95.2874 69.1878 97.9935Z"
          fill="#F3E4FF"
        />
        <Path
          d="M190.332 165.043C189.764 164.653 189.789 165.806 189.666 166.042C186.676 171.762 185.431 175.034 179 177.929C185.357 180.61 186.799 184.949 189.666 190.485C189.768 190.684 189.774 191.656 190.332 191.264C192.055 185.053 195.515 180.883 201.219 177.932C195.502 175.172 192.374 170.992 190.332 165.046V165.043Z"
          fill="#7F8AA5"
        />
        <Path
          d="M190.967 188.522C191.111 186.706 191.577 183.808 191.188 182.078C191.537 181.931 191.326 181.631 191.632 181.189C193.439 178.595 194.193 176.935 197.41 175.746C194.445 175.31 193.365 172.746 191.411 170.967C191.405 170.82 191.418 170.67 191.411 170.523C191.313 168.391 191.786 166.036 190.967 164.079C191.089 163.843 191.065 162.69 191.632 163.08C193.678 169.026 196.803 173.206 202.519 175.966C196.812 178.917 193.356 183.091 191.632 189.298C191.071 189.693 191.068 188.718 190.967 188.519V188.522Z"
          fill="#A6C2FF"
        />
        <Path
          d="M191.408 170.523C190.01 172.765 188.16 174.488 185.854 175.746C187.802 176.862 190.691 179.861 191.187 182.079C191.574 183.808 191.111 186.706 190.967 188.522C188.102 182.986 186.658 178.65 180.3 175.966C186.731 173.068 187.976 169.796 190.967 164.079C191.785 166.036 191.313 168.391 191.411 170.523H191.408Z"
          fill="#85ABFF"
        />
        <Path
          d="M191.409 170.968C191.537 174.369 191.954 177.736 191.629 181.189C191.323 181.631 191.534 181.931 191.185 182.079C190.688 179.861 187.799 176.862 185.852 175.746C188.158 174.491 190.01 172.765 191.406 170.523C191.412 170.67 191.399 170.82 191.406 170.968H191.409Z"
          fill="#5E89E7"
        />
        <Path
          d="M191.632 181.189C191.954 177.739 191.537 174.369 191.411 170.968C193.368 172.743 194.444 175.307 197.41 175.746C194.193 176.936 193.439 178.598 191.632 181.189Z"
          fill="#D8E4FF"
        />
        <Path
          d="M79.8882 119.31C81.1916 122.511 82.5932 124.364 86.0004 125.422C82.3263 126.566 80.9586 128.746 79.6674 132.199C79.2994 128.528 76.9319 126.86 74 125.201C77.5146 124.538 78.8026 122.459 79.8882 119.313V119.31Z"
          fill="#7F8AA5"
        />
        <Path
          d="M80.7684 118C82.0718 121.202 83.4733 123.054 86.8805 124.112C83.2065 125.256 81.8387 127.437 80.5476 130.89C80.1795 127.219 77.812 125.55 74.8801 123.891C78.3947 123.229 79.6827 121.15 80.7684 118.003V118Z"
          fill="#90ADF9"
        />
        <Path
          d="M162.888 194.31C164.192 197.511 165.593 199.364 169 200.422C165.326 201.566 163.959 203.746 162.667 207.199C162.299 203.528 159.932 201.86 157 200.201C160.515 199.538 161.803 197.459 162.888 194.313V194.31Z"
          fill="#7F8AA5"
        />
        <Path
          d="M163.768 193C165.072 196.202 166.473 198.054 169.881 199.112C166.206 200.256 164.839 202.437 163.548 205.89C163.18 202.219 160.812 200.55 157.88 198.891C161.395 198.229 162.683 196.15 163.768 193.003V193Z"
          fill="#C58CF0"
        />
        <Path
          d="M190.888 94.3096C192.192 97.5113 193.593 99.3637 197 100.422C193.326 101.566 191.959 103.746 190.667 107.199C190.299 103.528 187.932 101.86 185 100.201C188.515 99.5385 189.803 97.4592 190.888 94.3126V94.3096Z"
          fill="#7F8AA5"
        />
        <Path
          d="M191.768 93C193.072 96.2017 194.473 98.0541 197.881 99.1121C194.206 100.256 192.839 102.437 191.548 105.89C191.18 102.219 188.812 100.55 185.88 98.8913C189.395 98.2289 190.683 96.1496 191.768 93.0031V93Z"
          fill="#C58CF0"
        />
        <Defs>
          <LinearGradient
            id="paint0_linear_8631_23024"
            x1="129.548"
            y1="5"
            x2="129.548"
            y2="254.796"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#EEEBF3" />
            <Stop offset="1" stopColor="#B5B2BC" />
          </LinearGradient>
          <LinearGradient
            id="paint1_linear_8631_23024"
            x1="185.376"
            y1="5.00317"
            x2="185.376"
            y2="254.803"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#FBF6F6" />
            <Stop offset="1" stopColor="#C4C4C4" />
          </LinearGradient>
          <LinearGradient
            id="paint2_linear_8631_23024"
            x1="129.547"
            y1="5"
            x2="129.547"
            y2="254.796"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#EEE1E0" />
            <Stop offset="1" stopColor="#C6BAB9" />
          </LinearGradient>
          <LinearGradient
            id="paint3_linear_8631_23024"
            x1="130.002"
            y1="156.081"
            x2="81.4903"
            y2="204.592"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#EAD8FC" />
            <Stop offset="1" stopColor="#C18CF6" />
          </LinearGradient>
          <LinearGradient
            id="paint4_linear_8631_23024"
            x1="137.951"
            y1="194.671"
            x2="112.579"
            y2="191.47"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#D2AEF7" />
            <Stop offset="1" stopColor="#A777D7" />
          </LinearGradient>
          <LinearGradient
            id="paint5_linear_8631_23024"
            x1="130.066"
            y1="106.934"
            x2="101.851"
            y2="154.935"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#EAD8FC" />
            <Stop offset="1" stopColor="#C18CF6" />
          </LinearGradient>
          <LinearGradient
            id="paint6_linear_8631_23024"
            x1="148.856"
            y1="131.479"
            x2="123.888"
            y2="126.987"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#D2AEF7" />
            <Stop offset="1" stopColor="#A777D7" />
          </LinearGradient>
          <LinearGradient
            id="paint7_linear_8631_23024"
            x1="130.065"
            y1="113.57"
            x2="122.326"
            y2="130.067"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#265CEC" />
          </LinearGradient>
          <LinearGradient
            id="paint8_linear_8631_23024"
            x1="135.688"
            y1="242.078"
            x2="130.459"
            y2="234.753"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#265CEC" />
          </LinearGradient>
          <LinearGradient
            id="paint9_linear_8631_23024"
            x1="135.748"
            y1="221.67"
            x2="130.088"
            y2="214.211"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#265CEC" />
          </LinearGradient>
          <LinearGradient
            id="paint10_linear_8631_23024"
            x1="149.701"
            y1="68.9635"
            x2="127.111"
            y2="91.5543"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#265CEC" />
          </LinearGradient>
          <LinearGradient
            id="paint11_linear_8631_23024"
            x1="149.614"
            y1="22.0259"
            x2="124.576"
            y2="47.0636"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#265CEC" />
          </LinearGradient>
          <LinearGradient
            id="paint12_linear_8631_23024"
            x1="110.257"
            y1="68.9634"
            x2="110.257"
            y2="102.11"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#8BA9F9" />
          </LinearGradient>
          <LinearGradient
            id="paint13_linear_8631_23024"
            x1="110.258"
            y1="22"
            x2="110.258"
            y2="54.8103"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#8BA9F9" />
          </LinearGradient>
          <LinearGradient
            id="paint14_linear_8631_23024"
            x1="128.362"
            y1="132.653"
            x2="121.671"
            y2="136.427"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor="#96B1F9" />
            <Stop offset="1" stopColor="#265CEC" />
          </LinearGradient>
        </Defs>
      </G>
    </AccessibleSvg>
  )
}

export const FirstLiveMusicBookingUnlockedDetailed = styled(
  FirstLiveMusicBookingUnlockedDetailedSvg
).attrs(({ size, theme }) => ({
  size: size ?? theme.illustrations.sizes.fullPage,
}))``
