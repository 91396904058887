import * as React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from '../types'

const TikTokSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color: _color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    accessibilityLabel={accessibilityLabel}
    testID={testID}>
    <Path
      d="M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z"
      fill="black"
    />
    <Path
      d="M20.6906 20.3373V18.993C20.2318 18.919 19.7684 18.8785 19.3039 18.8717C14.807 18.8621 10.8257 21.8261 9.47531 26.1889C8.12495 30.5517 9.71889 35.3009 13.4103 37.9132C10.6452 34.9032 9.87738 30.5215 11.4499 26.7253C13.0224 22.929 16.6415 20.4272 20.6906 20.3373Z"
      fill="#25F4EE"
    />
    <Path
      d="M20.9396 35.5656C23.4539 35.5622 25.5206 33.5475 25.6307 30.9926V8.19354H29.7259C29.6423 7.71715 29.6024 7.23389 29.6067 6.75H24.0056V29.527C23.9125 32.0949 21.8409 34.1289 19.3145 34.1331C18.5596 34.1266 17.8169 33.9377 17.1478 33.5821C18.0274 34.8217 19.4366 35.5589 20.9396 35.5656Z"
      fill="#25F4EE"
    />
    <Path
      d="M37.3749 15.9294V14.6622C35.8678 14.6627 34.3941 14.2104 33.1389 13.3619C34.2392 14.663 35.7262 15.5643 37.3749 15.9294Z"
      fill="#25F4EE"
    />
    <Path
      d="M33.1388 13.3617C31.9023 11.9295 31.2207 10.0885 31.2212 8.18256H29.7261C30.1164 10.3123 31.3504 12.185 33.1388 13.3617Z"
      fill="#FE2C55"
    />
    <Path
      d="M19.3036 24.5679C17.146 24.5792 15.2729 26.0826 14.7604 28.2144C14.248 30.3462 15.2278 32.5591 17.1369 33.5817C16.1024 32.1291 15.9563 30.2092 16.7586 28.612C17.5609 27.0147 19.1761 26.0101 20.9395 26.0114C21.4097 26.0174 21.8767 26.0916 22.3263 26.2318V20.4356C21.8672 20.3657 21.4038 20.3288 20.9395 20.3254H20.6904V24.7332C20.2385 24.6099 19.7713 24.5543 19.3036 24.5679Z"
      fill="#FE2C55"
    />
    <Path
      d="M37.3748 15.9294V20.3372C34.5848 20.3317 31.8672 19.4334 29.6069 17.7697V29.351C29.595 35.13 24.9857 39.8084 19.3039 39.8084C17.1939 39.8123 15.1347 39.15 13.4103 37.9131C16.288 41.0611 20.7666 42.0973 24.7 40.5253C28.6334 38.9532 31.2203 35.0931 31.2212 30.7946V19.2463C33.489 20.8991 36.2099 21.7857 38.9999 21.7807V16.1058C38.4537 16.104 37.9091 16.0449 37.3748 15.9294Z"
      fill="#FE2C55"
    />
    <Path
      d="M29.6063 29.3507V17.7694C31.8734 19.4236 34.5949 20.3103 37.385 20.3038V15.8961C35.7366 15.5421 34.2461 14.6525 33.1381 13.3617C31.3498 12.185 30.1157 10.3123 29.7255 8.18256H25.6303V30.9926C25.5484 33.0276 24.2083 34.7866 22.2913 35.3753C20.3743 35.9639 18.3011 35.2531 17.1257 33.6042C15.2166 32.5816 14.2368 30.3687 14.7493 28.2369C15.2617 26.1051 17.1349 24.6016 19.2925 24.5904C19.7628 24.5947 20.23 24.6689 20.6792 24.8108V20.403C16.6074 20.4732 12.9609 22.9837 11.384 26.8027C9.80702 30.6217 10.5974 35.0276 13.3989 38.034C15.1402 39.2302 17.2024 39.8498 19.3033 39.8081C24.9851 39.8081 29.5944 35.1297 29.6063 29.3507Z"
      fill="white"
    />
  </AccessibleSvg>
)

export const TikTok = styled(TikTokSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
