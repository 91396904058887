import * as React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const VenueAccessibilitySVG = ({ color, size, accessibilityLabel, testID }: AccessibleIcon) => {
  return (
    <AccessibleSvg
      width={size}
      height={size}
      accessibilityLabel={accessibilityLabel}
      testID={testID}
      viewBox="0 0 48 48">
      <Path
        fill={color}
        d="M41.62 4H26.36C25.05 4 23.98 5.07 23.98 6.38V14.33C25.28 14.86 26.5 15.55 27.62 16.37V16.13C27.62 15.63 28.03 15.22 28.53 15.22C29.03 15.22 29.44 15.63 29.44 16.13V17.91C30.99 19.43 32.25 21.25 33.11 23.28C33.17 22.84 33.54 22.5 33.99 22.5C34.49 22.5 34.9 22.91 34.9 23.41V25.22C34.9 25.71 34.51 26.11 34.01 26.12C34.28 27.32 34.44 28.56 34.44 29.84C34.44 35.83 31.28 41.07 26.54 44.02H41.61C42.92 44.02 43.99 42.95 43.99 41.64V6.38C43.99 5.07 42.92 4 41.61 4H41.62ZM29.44 11.48C29.44 11.98 29.03 12.39 28.53 12.39C28.03 12.39 27.62 11.98 27.62 11.48V9.67C27.62 9.17 28.03 8.76 28.53 8.76C29.03 8.76 29.44 9.17 29.44 9.67V11.48ZM34.9 17.93C34.9 18.43 34.49 18.84 33.99 18.84C33.49 18.84 33.08 18.43 33.08 17.93V16.12C33.08 15.62 33.49 15.21 33.99 15.21C34.49 15.21 34.9 15.62 34.9 16.12V17.93ZM34.9 11.48C34.9 11.98 34.49 12.39 33.99 12.39C33.49 12.39 33.08 11.98 33.08 11.48V9.67C33.08 9.17 33.49 8.76 33.99 8.76C34.49 8.76 34.9 9.17 34.9 9.67V11.48ZM40.36 32.55C40.36 33.05 39.95 33.46 39.45 33.46C38.95 33.46 38.54 33.05 38.54 32.55V30.74C38.54 30.24 38.95 29.83 39.45 29.83C39.95 29.83 40.36 30.24 40.36 30.74V32.55ZM40.36 25.2C40.36 25.7 39.95 26.11 39.45 26.11C38.95 26.11 38.54 25.7 38.54 25.2V23.39C38.54 22.89 38.95 22.48 39.45 22.48C39.95 22.48 40.36 22.89 40.36 23.39V25.2ZM40.36 17.93C40.36 18.43 39.95 18.84 39.45 18.84C38.95 18.84 38.54 18.43 38.54 17.93V16.12C38.54 15.62 38.95 15.21 39.45 15.21C39.95 15.21 40.36 15.62 40.36 16.12V17.93ZM40.36 11.48C40.36 11.98 39.95 12.39 39.45 12.39C38.95 12.39 38.54 11.98 38.54 11.48V9.67C38.54 9.17 38.95 8.76 39.45 8.76C39.95 8.76 40.36 9.17 40.36 9.67V11.48Z"
      />
      <Path
        fill={color}
        d="M17.71 16.12C10.14 16.12 4 22.25 4 29.82C4 37.39 10.14 43.52 17.71 43.52C25.28 43.52 31.42 37.39 31.42 29.82C31.42 22.25 25.28 16.12 17.71 16.12ZM17 37.7C13.41 37.7 10.48 34.77 10.48 31.18C10.48 29 11.57 27.07 13.22 25.89L13.5 27.56C12.55 28.48 11.95 29.76 11.95 31.18C11.95 33.96 14.22 36.23 17.01 36.23C18.66 36.23 20.13 35.43 21.05 34.19L21.35 36.02C20.2 37.05 18.68 37.69 17.01 37.69L17 37.7ZM26.1 35.8L23.76 36.54C23.69 36.56 23.61 36.57 23.54 36.57C23.41 36.57 23.27 36.53 23.16 36.46C22.98 36.35 22.85 36.16 22.82 35.95L22.29 32.15H16.46C16.1 32.15 15.8 31.89 15.74 31.54L14.18 22.66C14.13 22.5 14.1 22.33 14.1 22.15C14.1 21.11 14.95 20.26 15.99 20.26C17.03 20.26 17.88 21.1 17.88 22.15C17.88 23.2 17.03 24.04 15.99 24.04C15.96 24.04 15.93 24.04 15.9 24.04L16.26 26.2H21.14C21.54 26.2 21.87 26.53 21.87 26.93C21.87 27.33 21.54 27.66 21.14 27.66H16.51L17.09 30.69H22.93C23.29 30.69 23.6 30.95 23.65 31.31L24.15 34.89L25.68 34.41C26.07 34.29 26.48 34.5 26.6 34.89C26.72 35.28 26.51 35.69 26.12 35.81L26.1 35.8Z"
      />
    </AccessibleSvg>
  )
}

export const VenueAccessibility = styled(VenueAccessibilitySVG).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
