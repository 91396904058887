import * as React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const QuestionSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    accessibilityLabel={accessibilityLabel}
    testID={testID}
    fill={color}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3333 10C18.3333 14.6024 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6024 1.66663 10C1.66663 5.39765 5.39759 1.66669 9.99996 1.66669C14.6023 1.66669 18.3333 5.39765 18.3333 10ZM9.99998 13.0843C9.47095 13.0843 9.04209 13.5131 9.04209 14.0421C9.04209 14.5712 9.47095 15 9.99998 15C10.529 15 10.9579 14.5712 10.9579 14.0421C10.9579 13.5131 10.529 13.0843 9.99998 13.0843ZM9.09237 11.5241C9.01892 11.9857 9.41209 12.3676 9.87952 12.3676C10.3469 12.3676 10.6236 11.9655 10.7937 11.5301C10.8673 11.3419 11.0119 11.1375 11.2923 10.8572L11.8196 10.3559C12.2319 9.95655 12.5206 9.59414 12.6855 9.26862C12.8504 8.93875 12.9329 8.58936 12.9329 8.22044C12.9329 7.40881 12.679 6.78164 12.1712 6.33893C11.6634 5.89188 10.9494 5.66836 10.0293 5.66836C9.1178 5.66836 8.39731 5.90273 7.8678 6.37148C7.58662 6.62245 7.37947 6.92505 7.24634 7.27929C7.04113 7.82535 7.54318 8.3181 8.12652 8.3181C8.54148 8.3181 8.83993 7.97581 9.11869 7.65611C9.14954 7.62073 9.18016 7.58562 9.21066 7.55128C9.22453 7.53566 9.23914 7.52001 9.25452 7.50429C9.44983 7.30464 9.70808 7.20481 10.0293 7.20481C10.7063 7.20481 11.0449 7.57157 11.0449 8.30507C11.0449 8.54813 10.9798 8.78033 10.8496 9.00169C10.7194 9.2187 10.4568 9.50299 10.0618 9.85455C9.67118 10.2018 9.40209 10.5555 9.25452 10.9158C9.18327 11.0897 9.12921 11.2925 9.09237 11.5241Z"
    />
  </AccessibleSvg>
)

export const Question = styled(QuestionSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.smaller,
}))``
